<template>
  <form class="form">
    <euro-alert v-if="disabled" :show="disabled" variant="danger">
      <div class="d-flex align-items-center">
        <i :class="icons.infoCircle" class="mr-2" :style="{ color: '#fff' }"></i>
        {{ $t("Unable to change service or activities") }}
      </div>
    </euro-alert>

    <div v-if="selectedServiceComp" class="form-group row">
      <div v-for="(item, idx) in services" :key="idx" class="col-6 mb-8">
        <label class="option pointer">
          <span class="option-control">
            <span class="radio">
              <input v-model="selectedServiceComp" :disabled="disabled || servicesDisabled || item.disabled"
                type="radio" :value="String(item.value)" name="services-radios" />
              <span></span>
            </span>
          </span>
          <span class="option-label">
            <span class="option-head">
              <span class="option-title">
                {{ item.title }}
              </span>
            </span>
            <span v-if="item.description" class="option-body">
              {{ item.description }}
            </span>
          </span>
        </label>
      </div>
    </div>

    <!-- Box common activities -->
    <div class="row">
      <div class="col-12">
        <h4 class="mt-5 mb-8 font-weight-bold text-dark">{{ $t("Choose the Mission Activity") }}</h4>
      </div>
      <div v-for="(activity, idx) in assignmentTypeComputed" :key="activity.id" class="col-4 mb-8">
        <label :id="`activity-${idx}`" class="option pointer">
          <span class="option-control">
            <span class="checkbox">
              <input v-model="selectedNacecodesComp" :disabled="disabled || activitiesDisabled" type="checkbox"
                :value="String(activity.id)" />
              <span></span>
            </span>
          </span>
          <span class="option-label">
            <span class="option-head">
              <span class="option-title">
                <span class="svg-icon mr-1 svg-icon-primary">
                  <inline-svg :src="iconName(activity.code)" />
                </span>
                {{ activity.code }}
              </span>
            </span>
            <span class="option-body">
              {{ activity.name }}
            </span>
          </span>
          <b-tooltip v-if="disabled" :target="`activity-${idx}`" triggers="hover">
            {{ $t("Unable to change the activities") }}
          </b-tooltip>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        {{ $t("If you do not find the type of activity click here") }}
      </div>
      <div class="col-12 mt-4">
        <validation-provider v-slot="{ errors }" name="nace codes" vid="nacecodes" rules="required">
          <euro-select ref="euroSelectNacecodes" v-model="selectedNacecodesComp" :multiselect-props="{
      multiple: true,
      disabled: disabled || activitiesDisabled,
      placeholder: $t('Select activities'),
    }" :error-messages="errors" :fetch-function="getNacesCode" asynchronous searchable></euro-select>
        </validation-provider>
      </div>
    </div>
  </form>
</template>

<script>
import { SERVICE_TYPE, CLIENT_TYPE } from "@/core/abstractions/secondment";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import EuroAlert from "@/view/components/alerts/EuroAlert.vue";
import icons from "@/core/config/icons";
import SecondmentNacecodeService from "@/core/services/secondment/secondment-nacecode.service";
import CompanyService from "@/core/services/company/company.service";
import eventBus from "@/core/services/event/eventBus"


export default {
  components: {
    EuroAlert,
  },
  props: {
    selectedNacecodes: {
      type: Array,
      required: true,
    },
    selectedService: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    servicesDisabled: {
      type: Boolean,
      default: false,
    },
    activitiesDisabled: {
      type: Boolean,
      default: false,
    },
    client: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    icons,
    // Used only when someone cancels the infragroup popup.
    selectedNacecodesBackup: [],
    nacecodes: [],
    noServiceProvision: [],
    commonActivitiesNoService: [],
    commonActivitiesNoServiceCodes: [],
    activitesSelectedList: [],
  }),

  computed: {
    ...mapGetters("user", ["getCurrentManagedCompanyId"]),

    services() {
      return [
        {
          value: SERVICE_TYPE.CLIENT,
          title: this.$t("Service for a final client"),
          description: this.$t("The term Service for a final client refers to a service to a person with whom you have a direct business relationship."),
          disabled: false,
        },
        {
          value: SERVICE_TYPE.INTRAGROUP,
          title: this.$t("Infragroup Service (for external parent, branch or subsidiary company)"),
          description: this.$t("Group companies are those companies that are part of the same recognised group of companies, i.e. branches or subsidiaries."),
          disabled: this.client && !this.isClientCompany,
        },
        {
          value: SERVICE_TYPE.CONTRACT_WORK,
          title: this.$t("Subcontract work"),
          description: this.$t(" The term Subcontract work refers to the service requested by a customer in the broader context of a contract with a third party customer who benefits from the service."),
          disabled: false,
        },
        {
          value: SERVICE_TYPE.NO_SERVICE_PROVISION,
          title: this.$t("Activities without services"),
          description: "",
          disabled: false,
        },
      ];
    },

    selectedNacecodesComp: {
      get() {
        return this.selectedNacecodes.map(el => String(el))
      },
      set(selectedNacecodes) {
        this.$emit("update:selectedNacecodes", selectedNacecodes);
      },
    },

    selectedServiceComp: {
      get() {
        return this.selectedService;
      },
      set(selectedService) {
        this.$emit("update:selectedService", selectedService);
      },
    },

    isClientCompany() {
      return this.client?.kind == CLIENT_TYPE.COMPANY;
    },
    assignmentTypeComputed() {
      return this.selectedService == 'no_service_provision' ? this.noServiceProvision : this.commonActivitiesNoService
    },
    defaultServiceLoaded() {
      return this.commonActivitiesNoService.length > 0 && this.noServiceProvision.length > 0
    }
  },
  watch: {
    selectedNacecodesComp: {
      handler(newVal, oldVal) {
        if (newVal != oldVal)
          this.activitiesSelected()
      },
      deep: true,
      inmediate: true
    },
    async selectedService(newSelectedService, oldSelectedService) {
      if (newSelectedService != oldSelectedService) {
        if (newSelectedService == SERVICE_TYPE.NO_SERVICE_PROVISION) {
          this.selectedNacecodesComp = [];
          this.activitesSelectedList = [];
          this.commonActivitiesNoServiceCodes = [];
          this.$refs.euroSelectNacecodes.asyncOptions = [];
          await this.$refs.euroSelectNacecodes.resetSelection();
        }
        await this.$refs.euroSelectNacecodes.searchChange("");
      }
      if (
        newSelectedService == SERVICE_TYPE.INTRAGROUP &&
        this.client &&
        !(await this.inCompanyGroup(this.getCurrentManagedCompanyId, this.client.id))
      ) {
        eventBus.$emit('reset-client-selected')
        const { isConfirmed } = await Swal.fire({
          title: this.$t("The selected client is not part of your group"),
          icon: "warning",
          text: this.$t("The client will be deselected, if you want to add it you can do from the GROUPS section"),
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: this.$t("Go to GROUPS section"),
          cancelButtonText: this.$t("Close"),
        });
        if (isConfirmed) {
          this.$router.push({
            path: '/legal-person/' + this.getCurrentManagedCompanyId + '/groups'
          });
        }
      }
    },
  },
  created() {
    if (this.selectedNacecodes.length) {
      this.activitiesSelected()
    }
  },
  methods: {
    //service for in check if is company group
    async inCompanyGroup(parentCompanyId, childCompanyId) {
      const parent = await CompanyService.getOne(parentCompanyId);
      const groups = parent.data.company_group;
      const companiesArray = groups.flatMap(el => el.companies).includes(childCompanyId);
      if (companiesArray) {
        return true;
      } else {
        return false;
      }
    },
    //joins the arrays to add the principal activities and the activities that are loaded asyn
    async activitiesSelected() {
      const nacecodesSelected = this.selectedNacecodes.length > 0 ? this.selectedNacecodes.join(",") : null;
      if (nacecodesSelected) {
        const res = await SecondmentNacecodeService.getAllNacesCode({
          fields: 'id,code,name,no_service_provision,common_activity',
          id_in: nacecodesSelected
        });

        this.activitesSelectedList = res.results.map(el => ({
          id: el.id,
          name: el.name,
          code: el.code
        }));
      }
    },
    async commonActivitiesFunction() {
      //call service for commonActivitiesNoService and no_service_provision
      const commonActivityCall = async (noServiceProvisionValue) => {
        const common_activity = true;
        const res = await SecondmentNacecodeService.getAllNacesCode({
          perPage: 20,
          page: 1,
          fields: 'id,code,name,no_service_provision,common_activity',
          no_service_provision: noServiceProvisionValue,
          common_activity: common_activity,
        });

        return res.results.map(el => ({
          id: el.id,
          name: el.name,
          code: el.code
        }));
      };
      //filters backend with :common_activity = false || no_service_provision  = true
      const common_activity = await commonActivityCall(false);
      const no_service_provision = await commonActivityCall(true);

      this.commonActivitiesNoService = common_activity.concat(no_service_provision)
      this.noServiceProvision = no_service_provision

      return true
    },
    async getNacesCode(search, perPage, page) {
      if (!this.defaultServiceLoaded) {
        await this.commonActivitiesFunction()
      }

      const offset = (page - 1) * perPage;
      const res = await SecondmentNacecodeService.getAllNacesCode({
        paginated: true,
        limit: search ? 50 : 20,
        search: search,
        perPage: perPage,
        page: page,
        fields: 'id,code,name,no_service_provision,common_activity',
        //for control when selectedService is no service provision using filters of the backend
        no_service_provision: this.selectedService == 'no_service_provision' ? true : null,
        common_activity: this.selectedService == 'no_service_provision' ? true : null,
      });

      let commonActivitiesList = []
      if (this.selectedService != 'no_service_provision') {
        commonActivitiesList = JSON.parse(JSON.stringify(this.commonActivitiesNoService))
      }
      // add selected activites
      if (this.activitesSelectedList.length) {
        this.activitesSelectedList.forEach(el => {
          let exist = commonActivitiesList.some(
            value => { return value.id == el.id });
          if (!exist) {
            commonActivitiesList.push(el)
          }
        })
      }

      let resultsFilterd = []
      if (offset == 0) {
        // init list default service
        this.commonActivitiesNoServiceCodes = []
        commonActivitiesList.forEach(el => {
          this.commonActivitiesNoServiceCodes.push(el.id)
        })

        resultsFilterd = res.results
        if (search) {
          resultsFilterd = res.results.filter(el => !this.commonActivitiesNoServiceCodes.includes(el.id))
          // add common activities
          resultsFilterd = resultsFilterd.concat(commonActivitiesList)
        } else {
          // add common activities
          resultsFilterd = commonActivitiesList.concat(resultsFilterd)
        }
      } else {
        resultsFilterd = res.results.filter(el => !this.commonActivitiesNoServiceCodes.includes(el.id))
      }


      return {
        count: res.count,
        results: resultsFilterd.map(el => ({
          value: String(el.id),
          text: el.name
        }))
      };
    },
    iconName(code) {
      switch (code) {
        case '33.1':
          return '/media/svg/icons/Tools/Tools.svg';
        case '33.1.U':
          return '/media/svg/icons/Code/Thunder-circle.svg';
        case '33.20':
          return '/media/svg/icons/Tools/Road-Cone.svg';
        case '43.99':
          return '/media/svg/icons/Tools/Shovel.svg';
        default:
          return '/media/svg/icons/Tools/Screwdriver.svg'
      }
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
  height: 100%;
}
</style>
