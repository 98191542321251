var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("Activities")) + " ")])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('secondment-activities-services-form', {
    attrs: {
      "selected-nacecodes": _vm.selectedNacecodes,
      "selected-service": _vm.selectedService,
      "client": _vm.client,
      "disabled": ""
    },
    on: {
      "update:selectedNacecodes": function updateSelectedNacecodes($event) {
        _vm.selectedNacecodes = $event;
      },
      "update:selected-nacecodes": function updateSelectedNacecodes($event) {
        _vm.selectedNacecodes = $event;
      },
      "update:selectedService": function updateSelectedService($event) {
        _vm.selectedService = $event;
      },
      "update:selected-service": function updateSelectedService($event) {
        _vm.selectedService = $event;
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }