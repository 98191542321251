var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "form"
  }, [_vm.disabled ? _c('euro-alert', {
    attrs: {
      "show": _vm.disabled,
      "variant": "danger"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('i', {
    staticClass: "mr-2",
    class: _vm.icons.infoCircle,
    style: {
      color: '#fff'
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Unable to change service or activities")) + " ")])]) : _vm._e(), _vm.selectedServiceComp ? _c('div', {
    staticClass: "form-group row"
  }, _vm._l(_vm.services, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "col-6 mb-8"
    }, [_c('label', {
      staticClass: "option pointer"
    }, [_c('span', {
      staticClass: "option-control"
    }, [_c('span', {
      staticClass: "radio"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedServiceComp,
        expression: "selectedServiceComp"
      }],
      attrs: {
        "disabled": _vm.disabled || _vm.servicesDisabled || item.disabled,
        "type": "radio",
        "name": "services-radios"
      },
      domProps: {
        "value": String(item.value),
        "checked": _vm._q(_vm.selectedServiceComp, String(item.value))
      },
      on: {
        "change": function change($event) {
          _vm.selectedServiceComp = String(item.value);
        }
      }
    }), _c('span')])]), _c('span', {
      staticClass: "option-label"
    }, [_c('span', {
      staticClass: "option-head"
    }, [_c('span', {
      staticClass: "option-title"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])]), item.description ? _c('span', {
      staticClass: "option-body"
    }, [_vm._v(" " + _vm._s(item.description) + " ")]) : _vm._e()])])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h4', {
    staticClass: "mt-5 mb-8 font-weight-bold text-dark"
  }, [_vm._v(_vm._s(_vm.$t("Choose the Mission Activity")))])]), _vm._l(_vm.assignmentTypeComputed, function (activity, idx) {
    return _c('div', {
      key: activity.id,
      staticClass: "col-4 mb-8"
    }, [_c('label', {
      staticClass: "option pointer",
      attrs: {
        "id": "activity-".concat(idx)
      }
    }, [_c('span', {
      staticClass: "option-control"
    }, [_c('span', {
      staticClass: "checkbox"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedNacecodesComp,
        expression: "selectedNacecodesComp"
      }],
      attrs: {
        "disabled": _vm.disabled || _vm.activitiesDisabled,
        "type": "checkbox"
      },
      domProps: {
        "value": String(activity.id),
        "checked": Array.isArray(_vm.selectedNacecodesComp) ? _vm._i(_vm.selectedNacecodesComp, String(activity.id)) > -1 : _vm.selectedNacecodesComp
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.selectedNacecodesComp,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = String(activity.id),
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selectedNacecodesComp = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedNacecodesComp = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedNacecodesComp = $$c;
          }
        }
      }
    }), _c('span')])]), _c('span', {
      staticClass: "option-label"
    }, [_c('span', {
      staticClass: "option-head"
    }, [_c('span', {
      staticClass: "option-title"
    }, [_c('span', {
      staticClass: "svg-icon mr-1 svg-icon-primary"
    }, [_c('inline-svg', {
      attrs: {
        "src": _vm.iconName(activity.code)
      }
    })], 1), _vm._v(" " + _vm._s(activity.code) + " ")])]), _c('span', {
      staticClass: "option-body"
    }, [_vm._v(" " + _vm._s(activity.name) + " ")])]), _vm.disabled ? _c('b-tooltip', {
      attrs: {
        "target": "activity-".concat(idx),
        "triggers": "hover"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Unable to change the activities")) + " ")]) : _vm._e()], 1)]);
  })], 2), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("If you do not find the type of activity click here")) + " ")]), _c('div', {
    staticClass: "col-12 mt-4"
  }, [_c('validation-provider', {
    attrs: {
      "name": "nace codes",
      "vid": "nacecodes",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          ref: "euroSelectNacecodes",
          attrs: {
            "multiselect-props": {
              multiple: true,
              disabled: _vm.disabled || _vm.activitiesDisabled,
              placeholder: _vm.$t('Select activities')
            },
            "error-messages": errors,
            "fetch-function": _vm.getNacesCode,
            "asynchronous": "",
            "searchable": ""
          },
          model: {
            value: _vm.selectedNacecodesComp,
            callback: function callback($$v) {
              _vm.selectedNacecodesComp = $$v;
            },
            expression: "selectedNacecodesComp"
          }
        })];
      }
    }])
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }