<template>
  <b-alert :variant="variant" :show="show">
    <slot />
  </b-alert>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
