<template>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-center">
          <h3 class="card-label font-weight-bolder mb-0">
            {{ $t("Activities") }}
          </h3>
        </div>
      </div>
      <div class="card-body">
        <secondment-activities-services-form :selected-nacecodes.sync="selectedNacecodes"
          :selected-service.sync="selectedService" :client="client" disabled></secondment-activities-services-form>
      </div>
    </div>
</template>

<style scoped>
.min-h-activity {
  min-height: 4rem;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { unsavedChangesSwal } from "@/core/helpers/swal";
import SecondmentActivitiesServicesForm from "@/view/components/forms/secondment/SecondmentActivitiesServicesForm.vue";
import { SERVICE_TYPE } from "@/core/abstractions/secondment";
export default {
  components: {
    SecondmentActivitiesServicesForm,
  },

  props: {
    secondmentActivities: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      selectedNacecodes: [],
      selectedService: SERVICE_TYPE.CLIENT,
    };
  },

  watch: {
    secondmentActivities: {
      deep: true,
      handler() {
        this.reset();
      },
    },
  },

  async created() {
    this.reset();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments") },
      { title: this.$t("Activities") },
    ]);
  },

  methods: {
    emitUpdateInfo() {
      this.$emit("update-activities", {
        activities: this.selectedNacecodes,
        service: this.selectedService,
      });
    },

    onSaveClick() {
      this.emitUpdateInfo();
    },

    onResetClick() {
      this.reset();
    },

    async reset() {
      const secondmentActivities = this.secondmentActivities[0];
      if (!secondmentActivities) {
        this.selectedNacecodes = [];
        this.selectedService = SERVICE_TYPE.CLIENT;
        return;
      }

      this.selectedService = secondmentActivities.type;
      await this.$nextTick();
      this.selectedNacecodes = secondmentActivities.activities.map(activity => {
        if (typeof activity === "object") return activity.id;
        return activity;
      });
    },

    hasUnsavedChanges() {
      const secondmentActivities = this.secondmentActivities[0] ?? null;
      if (this.selectedService != secondmentActivities?.type) return true;

      const ogNace = secondmentActivities?.activities.map(activity => {
        if (typeof activity === "object") return activity.id;
        return activity;
      });

      if (JSON.stringify(ogNace) != JSON.stringify(this.selectedNacecodes)) return true;

      return false;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.hasUnsavedChanges()) {
      next();
      return;
    }

    unsavedChangesSwal().then(result => {
      if (result.isConfirmed) {
        this.emitUpdateInfo();
        next();
      } else if (result.isDenied) {
        next();
      }
    });
  },
};
</script>
