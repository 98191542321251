import api from "../api.service";

class SecondmentNacecodeService {
  get PATH() {
    return "secondment-nacecode";
  }
  async getAllNacesCode({
    perPage,
    page,
    search = '',
    fields = null,
    no_service_provision = null,
    common_activity = null,
    id_in = null,
    limit = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      offset,
      search,
      limit
    };

    if (fields !== null) {
      params.fields = fields;
    }

    if (no_service_provision !== null) {
      params.no_service_provision = no_service_provision;
    }

    if (common_activity !== null) {
      params.common_activity = common_activity;
    }

    if (id_in !== null) {
      params.id_in = id_in;
    }
    const response = await api.get(`${this.PATH}/`, {
      params
    });
    return response.data;
  }
}

export default new SecondmentNacecodeService();